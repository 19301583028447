import React, { useState, useEffect, useRef } from 'react';
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [movFile, setMovFile] = useState(null);
  const [mp4File, setMp4File] = useState(null);
  const [isConverting, setIsConverting] = useState(false);
  const [ffmpegLoaded, setFfmpegLoaded] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const MAX_FILE_SIZE = 10 * 1024 * 1024 * 1024; // 10GB

  // Use useRef para manter a instância do FFmpeg
  const ffmpeg = useRef(
    createFFmpeg({
      log: true,
      corePath: `${process.env.PUBLIC_URL}/ffmpeg/ffmpeg-core.js`,
    })
  ).current;

  useEffect(() => {
    const loadFFmpeg = async () => {
      try {
        console.log('Iniciando carregamento do FFmpeg...');
        await ffmpeg.load();
        setFfmpegLoaded(true);
        console.log('FFmpeg carregado com sucesso!');
      } catch (error) {
        console.error('Erro ao carregar o FFmpeg:', error);
        toast.error('Erro ao carregar o FFmpeg.');
      }
    };
    loadFFmpeg();
  }, [ffmpeg]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        toast.error('O arquivo excede o tamanho máximo de 10GB.');
        return;
      }
      if (file.type === 'video/quicktime' || file.name.endsWith('.mov')) {
        setMovFile(file);
        setMp4File(null); // Resetar o arquivo de saída quando um novo arquivo for selecionado
        toast.success('Arquivo MOV selecionado com sucesso!');
      } else {
        toast.error('Por favor, selecione um arquivo .mov válido.');
      }
    }
  };

  const handleConvert = async () => {
    if (!movFile) {
      toast.error('Por favor, selecione um arquivo MOV.');
      return;
    }

    setIsConverting(true);
    console.log('Iniciando a conversão do arquivo...');

    try {
      // Escrever o arquivo no sistema de arquivos virtual do FFmpeg
      ffmpeg.FS('writeFile', 'input.mov', await fetchFile(movFile));
      console.log('Arquivo MOV escrito no sistema de arquivos do FFmpeg.');

      // Executar o comando de conversão
      await ffmpeg.run('-i', 'input.mov', 'output.mp4');
      console.log('Comando de conversão executado.');

      // Ler o arquivo convertido
      const data = ffmpeg.FS('readFile', 'output.mp4');
      console.log('Arquivo MP4 lido do sistema de arquivos do FFmpeg.');

      // Criar um Blob a partir dos dados do arquivo
      const mp4Blob = new Blob([data.buffer], { type: 'video/mp4' });
      setMp4File(mp4Blob);

      toast.success('Arquivo convertido com sucesso!');
      console.log('Conversão concluída com sucesso!');
    } catch (error) {
      console.error(error);
      toast.error('Erro na conversão do arquivo.');
    } finally {
      setIsConverting(false);
      console.log('Estado de conversão atualizado para falso.');
    }
  };

  const handleDownload = () => {
    if (!mp4File) {
      toast.error('Nenhum arquivo para baixar.');
      return;
    }

    const url = URL.createObjectURL(mp4File);
    const link = document.createElement('a');
    link.href = url;
    link.download = movFile.name.replace('.mov', '.mp4');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // Manipuladores de Drag and Drop
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      if (file.size > MAX_FILE_SIZE) {
        toast.error('O arquivo excede o tamanho máximo de 50MB.');
        return;
      }
      if (file.type === 'video/quicktime' || file.name.endsWith('.mov')) {
        setMovFile(file);
        setMp4File(null); // Resetar o arquivo de saída quando um novo arquivo for selecionado
        toast.success('Arquivo MOV selecionado com sucesso!');
        e.dataTransfer.clearData();
      } else {
        toast.error('Por favor, selecione um arquivo .mov válido.');
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-800 flex flex-col items-center justify-center text-white p-4">
      <h1 className="text-4xl mb-4">Conversor MOV para MP4</h1>
      <p className="mb-8 text-center">
        Converta seus arquivos MOV para MP4 de forma simples e rápida.
      </p>

      {/* Área de Entrada */}
      <div className="flex flex-col items-center mb-6 w-full max-w-md">
        <label className="mb-2 text-lg font-semibold">Arquivo de Entrada (.mov):</label>
        <div
          className={`relative flex items-center justify-center w-full h-16 bg-gray-700 rounded-lg border-4 transition border-dashed ${
            isDragging ? 'border-blue-500' : 'border-gray-700'
          }`}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <input
            type="file"
            accept=".mov"
            onChange={handleFileChange}
            className="w-full h-full opacity-0 absolute cursor-pointer"
          />
          <span className="text-gray-400">
            {movFile ? movFile.name : 'Arraste ou clique para selecionar'}
          </span>
        </div>
      </div>

      {/* Botão Converter */}
      <div className="w-full max-w-md mb-6">
        <button
          onClick={handleConvert}
          disabled={isConverting || !ffmpegLoaded || !movFile}
          className={`w-full h-12 bg-blue-500 hover:bg-blue-700 rounded-lg font-semibold ${
            (isConverting || !ffmpegLoaded || !movFile) && 'opacity-50 cursor-not-allowed'
          }`}
        >
          {isConverting ? 'Convertendo...' : 'Converter'}
        </button>
      </div>

      {/* Área de Saída */}
      <div className="flex flex-col items-center mb-4 w-full max-w-md">
        <label className="mb-2 text-lg font-semibold">Arquivo de Saída (.mp4):</label>
        <div className="w-full h-16 bg-gray-700 rounded-lg border-4 border-dashed flex items-center justify-center">
          <span className="text-gray-400">
            {mp4File ? movFile.name.replace('.mov', '.mp4') : 'Aguardando conversão...'}
          </span>
        </div>
      </div>

      {/* Botão Baixar */}
      <div className="w-full max-w-md mb-6">
        <button
          onClick={handleDownload}
          disabled={!mp4File}
          className={`w-full h-12 bg-green-500 hover:bg-green-700 rounded-lg font-semibold ${
            !mp4File ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          Baixar
        </button>
      </div>

      {/* Link de Doação */}
      <a
        href="https://donation.raulscheidemantel.com"
        target="_blank"
        rel="noopener noreferrer"
        className="mt-8 text-blue-400 hover:underline"
      >
        Ajude a manter o site no ar e sem anúncios.
      </a>

      <ToastContainer />

      {/* Animação de Carregamento */}
      {isConverting && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32"></div>
        </div>
      )}
    </div>
  );
}

export default App;

